.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(25px + 2vmin);
  color: white;
  background-size : cover;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.Company-name {
  background: linear-gradient(to right, #68E0CF, #229EFE, #68E0CF);
  -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
